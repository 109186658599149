


















import Vue from 'vue';
import Component from 'vue-class-component';
import Login from './views/Login.vue';

@Component
export default class App extends Vue {
  
}
